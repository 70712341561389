.alerts {
    --toastMaxWidth: 500px;

    position: fixed;
    top: 10%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 50%;
    max-width: var(--toastMaxWidth);
    max-height: 60vh;
    overflow-y: auto;
    z-index: 1000;
    background: #81cfe0;
}

    .alerts__alert {
        margin-top: 0;
        max-height: 30vh;
        overflow-y: auto;
    }

    .alerts__alert_type-ok {
            background: #26c281;
        }

    .alerts__alert_type-warn {
            background: #fcb941;
        }

    .alerts__alert_type-error {
            background: #f22613;
        }

    .alerts__alert:not(:last-child) {
            margin-bottom: 2vh;
        }

.toast-header {
    display: flex;
    justify-content: space-between;
}

.toast-header strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.toast-body {
    overflow: hidden;
    text-overflow: ellipsis;
}

.toast {
    max-width: var(--toastMaxWidth);
}

.toast-body {
    white-space: pre-line;
}
