@-webkit-keyframes loading-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loading-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.loading_inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        -webkit-transform: unset;
                transform: unset;
    }

.loading div {
    box-sizing: border-box;
    position: absolute;
    width: 8vh;
    height: 8vh;
    margin: 2vh;
    border-radius: 50%;
    -webkit-animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 6px solid transparent;
    border-top-color: #59abe3;
}

.loading div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.loading div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.loading div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}
