:root {
    --toolbarHeight: 6vh;
}

body {
    margin: 0;
    background-color: #f6f5f3;
    font-size: 1.2em;
}

a:hover {
    text-decoration: unset;
    color: #2574a9;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #3498db;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #89c4f4;
}

.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 100%;
    width: -webkit-max-content;
    width: max-content;
}

textarea.bp3-input {
    max-width: 150px;
}

*, *::before, ::after {
    box-sizing: unset;
}

label {
    display: unset;
    margin-bottom: 0;
}
