.help {
  padding: 25px;
}

  .help h2, .help h3 {
      cursor: pointer;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
  }

  .help h2:before, .help h3:before {
        content: '';
        display: inline-block;
        background: no-repeat 50% 50%;
        background-size: contain;
        background-image: url('i/paragraph.svg');
        width: 18px;
        height: 18px;
      }

  .help h2 {
      text-align: center;
      font-weight: bold;
  }

  .help h3 {
      margin-top: 5vh;
  }

  .help p {
      text-indent: 1em;
  }

  .help__import-template {
      display: flex;
      justify-content: center;
      align-content: center;
  }

  .help__import-template a {
          font-size: 3em;
          border: 2px #19b5fe dashed;
          width: -webkit-fit-content;
          width: fit-content;
      }

html {
    overflow-y: scroll;
}

@media screen and (min-width: 960px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
  }
}
